<template>
  <div class="body-header">
    <b-navbar
      toggleable="lg"
      variant="light"
      class="body-header border-bottom border-gray-300"
    >
      <b-navbar-brand v-if="showLogo" class="logo-nav">
        <a :href="url" :title="title">
          <img
            class="header-logo"
            src="../img/seeme-ai-logo.png"
            alt="SeeMe.ai - Create. Use. Share AI."
          />
        </a>
      </b-navbar-brand>
      <b-navbar-brand class="logo-nav">
        <b-nav-form>
          <b-form-input
            v-if="searchVisible"
            :placeholder="searchLabel"
            v-model="search"
            class="search-input"
            type="search"
          >
          </b-form-input>
        </b-nav-form>
      </b-navbar-brand>

      <b-navbar-brand class="pl-3">
        <a :href="url" :title="title">
          <div class="header header-title">
            {{ entityLabel }} {{ entity.name }}
          </div></a
        >

        <div class="header header-description">
          {{ entity.description | truncate(50) }}
        </div>
      </b-navbar-brand>
      <b-navbar-nav
        v-if="showButton"
        @click="action"
        class="ml-auto action-btn btn-primary"
      >
        <a right class="m-auto">{{ actionLabel }}</a>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";

export default {
  name: "Header",
  props: {
    entity: Object,
    action: Function,
    actionLabel: String,
    searchLabel: String,
    searchVisible: Boolean,
    hidden: Boolean,
    showLogo: Boolean,
    url: {
      type: String,
      default: "/"
    },
    title: {
      type: String,
      default: ""
    },
    entityLabel: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      search: ""
    };
  },
  computed: {
    showButton: function() {
      return this.action && !this.hidden;
    }
  },
  watch: {
    search(val) {
      this.$root.$emit("updated_search", val);
    }
  },
  filters: {
    truncate: function(value, limit) {
      return utils.truncate(value, limit);
    }
  }
};
</script>

<style>
.search-input {
  margin-left: 1rem;
}

.header-logo {
  width: 30px;
  display: inline-block;
}

.navbar {
  justify-content: normal;
}

.logo-nav {
  margin-right: 0 !important;
}
</style>
